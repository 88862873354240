import React from "react";
import { graphql } from "gatsby";
import ProjectComponent from "./project-component";
import Layout from "./layout";
import SEO from "./seo";

export default ({ data }) => {
  return (
    <Layout>
      <SEO meta={data.contentfulProject.seo} pathname={data.contentfulProject.slug} />
      <ProjectComponent project={data.contentfulProject} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query contentfulProjectBySlug($slug: String!) {
    contentfulProject(slug: { eq: $slug }) {
      seo {
        title
        description
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      id
      title
      credits
      slug
      year
      color
      media {
        file {
          contentType
          url
          fileName
        }
        fluid(maxWidth: 1700, quality: 100) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      thumbnails {
        fluid(maxWidth: 1700, quality: 100) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      description {
        raw
      }
    }
  }
`;
